import { CSSProperties, useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@primer/octicons-react";
import { ReceivedVouchers, SentVouchers } from "../../";

const Summary = ({
  title,
  style = {},
}: {
  action?: string;
  title: string;
  style?: CSSProperties;
}) => {
  const [show, setShow] = useState(false);
  return (
    <section style={style} className="right-bottom">
      <div className="bottom-inner">
        <div className="inner-paylinks">
          <div className="item-header" onClick={() => setShow(!show)}>
            <div className="item-header-left">{title}</div>
            <div className="item-header-right">
              <span className="icons" style={show ? { display: "none" } : {}}>
                <ChevronDownIcon size={24} />
              </span>
              <span className="icons" style={show ? {} : { display: "none" }}>
                <ChevronUpIcon size={24} />
              </span>
            </div>
          </div>
          <div
            style={show ? {} : { display: "none" }}
            className="inner-paylink"
          >
            {title.toLocaleLowerCase() === "purchased" ? (
              <SentVouchers />
            ) : (
              <ReceivedVouchers />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Summary;
