import { useContext, useState } from "react";
import { EyeIcon, ReplyIcon } from "@primer/octicons-react";
import { AuthContext, MainContext } from "../../contexts";
import { TokenItem, TokenPlaceholder } from "..";
import { VoucherItem } from "../../models";
import { dateFormatter } from "../../modules";

const SentVouchers = () => {
  const { currentUser } = useContext(AuthContext);
  const { sentVouchers, loadingTokens } = useContext(MainContext);
  const [currentToken, setCurrentToken] = useState<any | VoucherItem>();
  const [showMore, setShowMore] = useState(false);

  const handleBack = () => setShowMore(false);

  const list = sentVouchers && sentVouchers.length > 0 ? sentVouchers : [];
  const Items = list.map((item: VoucherItem) => (
    <TokenItem
      key={item.id}
      data={item}
      setCurrentToken={setCurrentToken}
      showMore={showMore}
      setShowMore={setShowMore}
    />
  ));

  return (
    <section>
      {loadingTokens ? (
        <TokenPlaceholder />
      ) : Items.length === 0 ? (
        <div className="no-links">
          <span style={{ marginLeft: 5 }}>
            You have <b>0</b> Vouchers
          </span>
        </div>
      ) : (
        <div className="link-items fade-in">
          {showMore ? (
            <div className="voucher-info fadein">
              <div className="note-desc" style={{ borderRadius: 0 }}>
                <div
                  className="voucher-token-header"
                  onClick={handleBack}
                  style={{ color: "#259c8c", fontWeight: 500 }}
                >
                  <span className="voucher-info-back">
                    {" "}
                    <ReplyIcon size={16} />{" "}
                    <span style={{ marginLeft: 5 }}>Back</span>
                  </span>
                </div>
                <div className="note-desc-text">
                  {currentToken.sender === currentUser?.phoneNumber
                    ? `KES ${currentToken.value.toLocaleString()} voucher token, balance KES ${currentToken.balance.toLocaleString()}`
                    : currentToken.note}
                </div>
                <div>
                  <div>
                    {currentToken.recipients.length > 0 ? (
                      <span>
                        {currentToken.recipients.includes(
                          currentUser?.phoneNumber
                        ) ? (
                          <span className="voucher-tag-label">
                            <EyeIcon size={16} />{" "}
                            <span className="voucher-tag-label-text">
                              {currentToken.sender}
                            </span>
                          </span>
                        ) : (
                          <span className="voucher-tag-label">
                            <EyeIcon size={16} />{" "}
                            <span className="voucher-tag-label-text">
                              {currentToken.recipients.join(",")}
                            </span>
                          </span>
                        )}
                      </span>
                    ) : (
                      <span className="voucher-tag-label">
                        <EyeIcon size={16} />
                        <span className="voucher-tag-label-text">All</span>
                      </span>
                    )}
                  </div>
                  <div className="voucher-date-created">
                    <span>Date :</span>
                    <span>
                      {dateFormatter(currentToken.date_created).created_on},{" "}
                      {dateFormatter(currentToken.date_created).created_at},{" "}
                      {dateFormatter(currentToken.date_created).time_since}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            Items
          )}
        </div>
      )}
    </section>
  );
};

export default SentVouchers;
