import { useEffect, useState, createContext } from "react";
import { useVouchersCollection } from "../../hooks";
import { MainContextInterface, VoucherItem } from "../../models";

const MainContext = createContext<MainContextInterface>({
  wallet: {
    balance: 0,
    phoneNumber: undefined,
  },
  loadBalance: true,
  setLoadBalance: () => null,
  sentVouchers: [],
  setSentVouchers: () => null,
  receivedVouchers: [],
  setReceivedVouchers: () => null,
  loadingTokens: false,
});

const MainContextProvider = ({ children }: any) => {
  const [wallet, setWallet] = useState<{
    balance: 0;
    phoneNumber: undefined;
  }>({ balance: 0, phoneNumber: undefined });
  const [loadBalance, setLoadBalance] = useState(true);
  const [sentVouchers, setSentVouchers] = useState<VoucherItem[]>([]);
  const [receivedVouchers, setReceivedVouchers] = useState<VoucherItem[]>([]);
  const [data, loadingTokens] = useVouchersCollection();

  useEffect(() => {
    if (data) {
      setSentVouchers(data.sent);
      setReceivedVouchers(data.received);
    } else {
      setSentVouchers([]);
      setReceivedVouchers([]);
    }
    return () => {
      setSentVouchers([]);
      setReceivedVouchers([]);
    };
  }, [data, setReceivedVouchers, setSentVouchers]);

  return (
    <MainContext.Provider
      value={{
        wallet,
        setWallet,
        loadBalance,
        setLoadBalance,
        sentVouchers,
        setSentVouchers,
        receivedVouchers,
        setReceivedVouchers,
        loadingTokens,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainContextProvider };
