interface CategoryItem {
  date_created: string;
  date_updated: string;
  id: string;
  name: string;
  sub_categories: string[];
}

interface Wallet {
  active: boolean;
  balance: number;
  currency: string[];
  date_created: {
    _seconds: number;
    _nanoseconds: number;
  };
  date_updated: {
    _seconds: number;
    _nanoseconds: number;
  };
  email: string | null;
  email_verified: boolean;
  id: string;
  limit: number;
  phone_number: string;
  pro: boolean;
  username: string;
  verified: boolean;
}

export enum BuyProductStep {
  Initiate = 1,
  Verify = 2,
}

export enum VoucherSource {
  Merchant = "merchant",
  User = "user",
}

interface VerifyPayload {
  transaction_ref?: string;
  token_ref?: string;
  merchant_request_id?: string;
}

interface BuyProductPayload extends VerifyPayload {
  step: BuyProductStep;
  amount: number;
  redirect_url?: string;
  currency: string;
  maturity_date: string;
  payment: PaymentObject;
  partner?: { id: string; name: string };
  partner_id?: string;
  recipients: string[];
  use: "express" | "restricted";
  sender: string;
  note?: string;
  type?: "open" | "closed";
  source?: VoucherSource;
}

interface PaymentType {
  name: string;
  redirect_url: string;
  desc: string;
  type: string;
  code: number;
  slug: string;
  provider: "safaricom" | "airtel";
}

interface PaymentObject {
  type: string;
  provider: "safaricom" | "airtel";
  phone_number: string;
}
interface TokenPayload {
  sender: string;
  amount: number;
  currency: string;
  payment: PaymentObject;
  recipients: string[];
}

interface Token {
  balance: number;
  cid: string;
  currency: string;
  date_created: {
    created_at: string;
    created_on: string;
  };
  date_updated: {
    created_at: string;
    created_on: string;
  };
  expired: boolean;
  expiry_date: string;
  from: {
    phone: string;
    name: string;
    email: string;
  };
  locked: boolean;
  note: string;
  open: true;
  partner: {
    id: number | string;
    name: string;
  };
  payment: {
    provider: string;
    type: string;
  };
  payee: string[];
  to: string[];
  token: string;
  type: string;
  used: boolean;
  valid: boolean;
  value: number;
}

interface VoucherItem {
  id?: string;
  sender: string;
  cid: string;
  code: string;
  type: "open" | "closed";
  balance: number;
  locked: boolean;
  partner: {
    id: string;
    name: string;
  };
  open: true;
  voucher_code: string;
  value: number;
  note?: string;
  recipients: string[];
  date_created: {
    created_at: string;
    created_on: string;
  };
}

interface TokenItemProps {
  to: string[];
  from: {
    phone: string;
  };
  cid: string;
  balance: number;
  locked: boolean;
  partner: {
    id: string;
    name: string;
  };
  open: true;
  token: string;
  value: number;
}

interface PayoutRequest {
  amount: number;
  date: {
    created_at: string;
    created_on: string;
    time_since: string;
  };
  payee: string;
  status: string;
  voucher_code: string;
  ref: string;
}

type errorResponse =
  | {
      code?: string;
      message?: string;
      success?: boolean;
    }
  | any
  | unknown;

interface Transactions {
  payout: any[];
  purchase: any[];
}

interface PayoutSnapshot {
  payee: string;
  type: string;
  amount: number;
  teller: {
    email: string;
    name: string;
  };
  token: string;
  verified: boolean;
  open: boolean;
  status: string;
  phone: string;
  date_created: {
    seconds: number;
    nanoseconds: number;
  };
  payer: "+254713850804";
  date_updated: {
    seconds: number;
    nanoseconds: number;
  };
  partner_id: string;
  id: string;
}

type UseAPI =
  | CategoryItem[]
  | Token[]
  | VoucherItem[]
  | PaymentType[]
  | TokenPayload[]
  | BuyProductPayload[]
  | TokenItemProps[]
  | Wallet[]
  | PayoutRequest[]
  | Transactions
  | errorResponse;

interface TransactionItem {
  amount: number;
  date_created: {
    created_at: string;
    created_on: string;
    time_since: string;
  };
  date_updated: {
    created_at: string;
    created_on: string;
    time_since: string;
  };
  id: string;
  income: number;
  partner: {
    id: string;
    name: string;
  };
  payment: {
    type: string;
    provider: string;
    phone_number: string;
    mpesa_checkout_request_id?: string;
    mpesa_merchant_request_id?: string;
  };
  phone_number: string;
  ref: string;
  status: "completed" | "rejected" | "pending" | "initiated" | "failed";
  type: "purchase" | string;
  voucher_code: string;
  voucher_id: string;
  voucher_type: "open" | "closed";
}

interface HeaderItem {
  label: string;
}
interface ProductItem {
  active: boolean;
  category: {
    name: string;
    id: string;
  };
  countries: string[];
  date_created: {
    created_at: string;
    created_on: string;
    time_since: string;
  };
  date_updated: {
    created_at: string;
    created_on: string;
    time_since: string;
  };
  description: string;
  id: string;
  partner_id: string;
  regions: string[];
  title: string;
}

export type {
  CategoryItem,
  BuyProductPayload,
  PaymentType,
  TokenPayload,
  Token,
  VoucherItem,
  Transactions,
  UseAPI,
  TokenItemProps,
  Wallet,
  PayoutRequest,
  errorResponse,
  PayoutSnapshot,
  TransactionItem,
  HeaderItem,
  ProductItem,
};
