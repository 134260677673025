import {
  ArrowLeftIcon,
  ArrowUpRightIcon,
  CheckIcon,
  CopyIcon,
} from "@primer/octicons-react";
import empty_svg from "assets/images/buy/nothing.svg";
import { BalanceComponent, Loader, Sidebar, Summary } from "components";
import { AuthContext } from "contexts";
import { TransactionItem } from "models/api.model";
import { pwa } from "pwafire";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { apiService } from "services";

import "./transactions.css";

const getBackgroundColor = (
  status: "completed" | "rejected" | "pending" | "initiated" | "failed"
) => {
  return status.toLowerCase() === "completed"
    ? "#008F4B"
    : status.toLowerCase() === "rejected" || status.toLowerCase() === "failed"
    ? "#d90c34"
    : status.toLowerCase() === "pending"
    ? "#F8A2F9"
    : status.toLowerCase() === "initiated"
    ? "#0969D9"
    : "#008F4B";
};

const PurchaseItem = ({ item }: { item: TransactionItem }) => {
  const [tokenCopied, setTokenCopied] = useState(false);
  return (
    <div className="tx-item">
      <div className="tx-object tx-item-cell">
        <div className="tx-icon">
          <ArrowUpRightIcon size={24} />
        </div>
        <div>
          <div className="tx-object-name">{item.partner?.name}</div>
          <div className="tx-object-amount">
            KES {item.amount.toLocaleString()}
          </div>
          <div className="tx-object-id">#{item.id}</div>
        </div>
      </div>
      <div className="tx-item-cell">
        <div className="tx-token">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            <div>#{item.voucher_code}</div>
            {item.status.toLowerCase() === "completed" && (
              <span
                className="tx-token-copy"
                style={{
                  color: tokenCopied ? "#008F4B" : "#6c757d",
                  backgroundColor: tokenCopied ? "#e6ffe6" : "#f8f9fa",
                  borderColor: tokenCopied ? "#e6ffe6" : "#e5e5e5",
                }}
                onClick={() => {
                  pwa
                    .copyText(item.voucher_code)
                    .then((res) => setTokenCopied(res.ok));
                  setTimeout(() => setTokenCopied(false), 1000);
                }}
              >
                {tokenCopied ? <CheckIcon size={12} /> : <CopyIcon size={12} />}
              </span>
            )}
          </div>
          <div className="tx-date">{item.date_updated.time_since}</div>
        </div>
      </div>

      <div className="tx-item-cell tx-ctas">
        <div
          style={{
            color: getBackgroundColor(item.status),
            borderColor: getBackgroundColor(item.status),
          }}
          className="tx-status"
        >
          {item.status}
        </div>
      </div>
    </div>
  );
};

const Transactions = () => {
  const location = useLocation();
  const { currentUser, idToken, hostAPI } = useContext(AuthContext);
  const [transactions, setTransactions] = useState<{
    payout: {
      sent: TransactionItem[];
      received: TransactionItem[];
    };
    purchase: TransactionItem[];
  }>({
    payout: {
      sent: [],
      received: [],
    },
    purchase: [],
  });
  const [transactionData, setTransactionData] = useState<TransactionItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);

  const [initialData, setInitialData] = useState<TransactionItem[]>([]);
  const [lastSegment, setLastSegment] = useState("");

  useEffect(() => {
    if (currentUser)
      document.title = `Activity ${
        currentUser.displayName ? `- ${currentUser.displayName}` : ``
      }`;
    const path = location.pathname;
    const segment_array = path.split("/");
    const last_segment: string = segment_array.pop() || "";
    setLastSegment(last_segment);
  }, [currentUser, location]);

  useEffect(() => {
    (async () => {
      try {
        if (idToken && hostAPI && currentUser && currentUser.phoneNumber) {
          const res = await apiService.getData({
            url: `${hostAPI}/transactions/user/${currentUser.phoneNumber}`,
            method: "get",
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });
          res.data
            ? setTransactions(res.data)
            : setTransactions({
                payout: {
                  sent: [],
                  received: [],
                },
                purchase: [],
              });
          setLoading(false);
        } else {
          setLoading(true);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => {
      setTransactions({
        payout: {
          sent: [],
          received: [],
        },
        purchase: [],
      });
    };
  }, [idToken, hostAPI, currentUser]);

  useEffect(() => {
    const getPages = (data: any[]) => {
      setInitialData(data);
    };
    if (transactions) {
      const payouts = transactions.payout;
      const purchases = transactions.purchase;
      if (location.pathname === "/transactions/requests/sent") {
        setTransactionData(payouts.sent);
        getPages(payouts.sent);
      } else if (location.pathname === "/transactions/requests/received") {
        setTransactionData(payouts.received);
        getPages(payouts.received);
      } else if (location.pathname === "/transactions/purchases") {
        purchases.length === 0 ? setEmpty(true) : setEmpty(false);
        setTransactionData(purchases);
        getPages(purchases);
      }
      return () => {
        setTransactionData([]);
      };
    }
  }, [transactions, location]);

  useEffect(() => {
    if (initialData) setTransactionData(initialData.slice(0, 50));
  }, [initialData]);

  return (
    <main className="page-view">
      <section className="flex">
        <section className="flex-nav">
          <Sidebar user={currentUser} />
        </section>
        <section className="flex-left">
          <div className="inner-left transactions-flex">
            <div className="left-header">
              <NavLink className="account-page-flexbox" to="/transactions">
                <ArrowLeftIcon size={24} />
                <div className="account-page-back">
                  {" "}
                  <div className="left-h1">{lastSegment}</div>
                </div>
              </NavLink>
            </div>
            <section className="overflow fade-in">
              {loading ? (
                <div
                  style={{ margin: "20px auto" }}
                  className="loader-container"
                >
                  <Loader size={35} strokeWidth={2.75} />
                  <div style={{ margin: "5px auto" }}>Loading...</div>
                </div>
              ) : empty ? (
                <section className="is-empty">
                  <div className="is-empty-title">No transactions yet</div>
                  <img className="is-empty-img" src={empty_svg} alt="Empty" />
                </section>
              ) : (
                <section className="transactions">
                  <div className="tx-header">
                    <div className="tx-header-title">Transactions</div>
                    <div className="transactions-header-count">
                      {transactionData.length}{" "}
                      {transactionData.length === 1 ? "item" : "items"}
                    </div>
                  </div>
                  <div className="transactions-body">
                    {transactionData.map((item, index) => (
                      <PurchaseItem key={index} item={item} />
                    ))}
                  </div>
                </section>
              )}
            </section>
          </div>
        </section>
        <section style={{ display: "none" }} className="flex-right">
          <section className="flex-container">
            <section className="right-top">
              <div className="top-inner">
                <BalanceComponent />
              </div>
            </section>
            <Summary title="Purchased" />
          </section>
        </section>
      </section>
    </main>
  );
};

export default Transactions;
