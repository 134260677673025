import { VerifiedIcon } from "@primer/octicons-react";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext, MainContext } from "../../contexts";
import { useWalletCollection } from "../../hooks";

const BalanceComponent = () => {
  const { wallet, setWallet } = useContext(MainContext);
  const { currentUser } = useContext(AuthContext);
  const [data] = useWalletCollection({
    uid: currentUser?.uid || "",
  });

  useEffect(() => {
    if (data) {
      setWallet(data);
    } else {
      setWallet({
        balance: 0,
        limit: 0,
      });
    }
    return () => {
      setWallet({
        balance: 0,
        limit: 0,
      });
    };
  }, [data, setWallet]);

  return (
    <div className="wallet-balance">
      <p>Account ID</p>
      <h1 style={{ display: "none" }} className="balance">
        KES {wallet?.balance}
      </h1>
      <small style={{ display: "flex", alignItems: "center", marginTop: 5 }}>
        <span style={{ color: "#259c8c" }}>
          <VerifiedIcon />
        </span>{" "}
        <span style={{ marginLeft: 5 }}>
          {currentUser?.phoneNumber || (
            <Link to="/account/phone">(Add Phone)</Link>
          )}
        </span>
      </small>
    </div>
  );
};

export default BalanceComponent;
