import { useContext, useEffect, useState } from "react";
import pwafire from "pwafire";
import { CheckIcon, CopyIcon } from "@primer/octicons-react";
import { AuthContext } from "../../contexts";
import { VoucherItem } from "../../models";
import axios from "axios";

const { pwa } = pwafire;

const TokenComponent = ({
  data,
  setCurrentToken,
  showMore,
  setShowMore,
}: {
  data: VoucherItem;
  setCurrentToken?: any;
  showMore?: any;
  setShowMore?: any;
}) => {
  const { balance, partner, value, type, sender, id, code } = data;
  const { currentUser, hostAPI, idToken } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    setIsLocked(!!data?.locked);
  }, [data]);

  const handleCopy = async () => {
    try {
      await pwa.copyText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    } catch (error) {
      setCopied(false);
    }
  };

  const handleLock = async () => {
    try {
      if (idToken && hostAPI && !updating) {
        setUpdating(true);
        const res = await axios.patch(
          `${hostAPI}/vouchers/${id}/`,
          { locked: !isLocked },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        if (res && res.data) {
          const tokenRes = res.data.data;
          setUpdating(false);
          setIsLocked(tokenRes.locked);
        } else {
          setUpdating(false);
        }
      }
    } catch (error) {
      setUpdating(false);
    }
  };

  return (
    <div
      className="link-item"
      style={showMore ? { backgroundColor: "rgb(250, 251, 252)" } : {}}
    >
      <div style={showMore ? { display: "none" } : { padding: 15 }}>
        {" "}
        <div className="flex-links">
          <div className="links-left">
            <span className="flex-row">
              {partner?.name}
              <b style={{ marginLeft: 5 }}>KES {value.toLocaleString()}</b>{" "}
              <span className="token-label-small">{type}</span>
            </span>
          </div>
          <div className="links-right">
            <span
              className="copyIcon"
              style={copied ? { color: "#28a745" } : {}}
              onClick={handleCopy}
            >
              {copied ? <CheckIcon /> : <CopyIcon size={12} />}
            </span>
          </div>
        </div>
        <div className="payment-note">
          <span className="note-color">
            Balance KES {balance.toLocaleString()} / #{code}
          </span>
        </div>
        <div className="links-footer">
          <span
            style={
              sender === currentUser?.phoneNumber
                ? updating
                  ? {
                      background: "#fff",
                      color: "#259c8c",
                      border: "1px solid #ddd",
                    }
                  : {}
                : { display: "none" }
            }
            onClick={handleLock}
            className="reclaim-link"
          >
            {updating ? (
              <span style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginLeft: 5 }}>Updating</span>
              </span>
            ) : isLocked ? (
              "Unlock"
            ) : (
              "Lock"
            )}
          </span>
          <span
            style={sender === currentUser?.phoneNumber ? { marginLeft: 5 } : {}}
            onClick={() => {
              setShowMore(true);
              setCurrentToken(data);
            }}
            className="reclaim-link"
          >
            More
          </span>
        </div>
      </div>
    </div>
  );
};

export default TokenComponent;
