import { AuthContext } from "contexts";
import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { VoucherItem } from "models";
import { fb } from "modules";
import { useContext, useEffect, useState } from "react";

const useVouchersCollection = () => {
  const { currentUser } = useContext(AuthContext);

  const [data, setData] = useState<{
    sent: VoucherItem[];
    received: VoucherItem[];
  }>({
    sent: [],
    received: [],
  });
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        if (!currentUser?.phoneNumber) {
          setData({ sent: [], received: [] });
          setLoading(false);
          return;
        }

        const userPhone = currentUser.phoneNumber.toString();
        const vouchersRef = fb.collection(fb.db, "vouchers");
        const baseQuery = [
          fb.where("used", "==", false),
          fb.where("valid", "==", true),
          fb.orderBy("date_created", "desc"),
        ];

        const purchaseQuery = fb.query(
          vouchersRef,
          fb.where("sender", "==", userPhone),
          ...baseQuery
        );

        const receivedQuery = fb.query(
          vouchersRef,
          fb.where("recipients", "array-contains", userPhone),
          ...baseQuery
        );

        const partners = await fb.getDocs(fb.collection(fb.db, "partners"));

        const partnersData = partners.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        })) as { id: string; name: string }[];

        const processSentSnapshot = (querySnapshot: QuerySnapshot) => {
          const list = querySnapshot.docs
            .map(
              (doc) =>
                ({
                  ...doc.data(),
                  id: doc.id,
                } as DocumentData)
            )
            .map((item) => ({
              ...item,
              partner: {
                id: item.partner_id,
                name: partnersData.find(
                  (partner) => partner.id === item.partner_id
                )?.name,
              },
            })) as VoucherItem[];
          setData((prev) => ({
            ...prev,
            sent: list,
          }));
          setLoading(false);
        };

        const processReceivedSnapshot = (querySnapshot: QuerySnapshot) => {
          const list = querySnapshot.docs
            .map(
              (doc) =>
                ({
                  ...doc.data(),
                  id: doc.id,
                } as DocumentData)
            )
            .map((item) => ({
              ...item,
              partner: {
                id: item.partner_id,
                name: partnersData.find(
                  (partner) => partner.id === item.partner_id
                )?.name,
              },
            })) as VoucherItem[];

          setData((prev) => ({
            ...prev,
            received: list,
          }));
          setLoading(false);
        };

        fb.onSnapshot(purchaseQuery, processSentSnapshot);
        fb.onSnapshot(receivedQuery, processReceivedSnapshot);
      } catch (error) {
        setError(error);
        setLoading(false);
        setData({
          sent: [],
          received: [],
        });
      }
    })();
  }, [currentUser]);
  return [data, loading, error];
};

export default useVouchersCollection;
